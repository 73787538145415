import AddressLocation from './AddressLocation';
import { MemberField } from './ClientMemberFields';
import User from './User';

export interface ScriptexAuthStatus {
  isAuthenticated: boolean;
}

export enum LoginMethods {
  Password = 'password',
  Saml = 'saml',
  Oidc = 'oidc',
}

export type LoginResponse = {
  user: User;
  twoFactorRequired: boolean;
} & AuthResponse;

export type TwoFAResponse = {
  id: string;
  userId: string;
  registered: boolean;
  activated: boolean;
  locked: boolean;
} & AuthResponse;

export type TwoFAActivateResponse = {
  user: User;
} & TwoFAResponse;

export type TwoFARegistrationResponse = {
  userId: string;
  secret: string;
  uri: string;
  requiresActivation: boolean;
} & AuthResponse;

export type AuthResponse = {
  token: string;
  expiresUtc: string;
  refreshToken: string;
  refreshTokenExpiryUtc: string;
};

export type SamlRedirectResponse = {
  redirectUrl: string;
};

export type UserProfile = {
  email: string;

  firstName: string;
  lastName: string;
  languageCode: string;
  phoneNumber?: string | null;
  birthday?: string | null;

  address?: AddressLocation | null;
  userImageId?: string;

  clientCustomData?: Record<string, UserClientSpecificCustomData>;

  keepGroupAssignments?: boolean;
  addedAsIndividual?: boolean;
};

export type UserClientSpecificCustomData = {
  departmentId?: string | null;
  positionId?: string | null;
  groupId?: string | null;
  startDate?: string | null;
  employeeId?: string | null;
  officeLocation?: AddressLocation | null;
};

export type RegistrationFlowStatus = {
  registrationActions: string;
  user: User;
  memberFields: MemberField[];
} & AuthResponse;
